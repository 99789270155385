
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'form-placeholder-dropdown',
  emits: ['placeholderSelect'],
  props: {
    placeHolderOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    placeholderSelector(option) {
      this.$emit('placeholderSelect', option);
    },
  },
});
