
import { defineComponent } from 'vue';
import Placeholder from '@/components/forms/PlaceholderDropdown.vue';
import Editor from '@tinymce/tinymce-vue';
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import { Template } from '@/models/TemplateModel';

const TINY_MCE_API_KEY = 'm90gcfxe9n3uual8tazg8h2yiaq3pu9fumvhvh94wnrpl91t';
const MAX_LENGTH = 360;

const initValues = {
  subject: '',
  body: '',
} as {} as unknown as Template;

export default defineComponent({
  name: 'sms-template-editor-form',
  emits: ['updateTemplate', 'createTemplate'],
  components: {
    Editor,
    Placeholder,
  },
  props: {
    template: {
      type: Object,
    },
    placeholders: {
      type: Object,
    },
    title: {
      type: String,
    },
    pageLoading: {
      type: Boolean,
    },
    formSubmitted: {
      type: Boolean,
    },
  },
  data: () => ({
    api_key: TINY_MCE_API_KEY,
    max_length: MAX_LENGTH,
    formData: initValues,
    subjectInput: {} as typeof HTMLInputElement,
    updateMode: false,
    previewModel: '',
    isEdit: '',
    currentlyActiveField: '',
    config: {
      selector: 'textarea',
      auto_focus: 'input',
      menubar: '',
      toolbar: '',
      height: '200',
      plugins: 'code',
      contextmenu: 'link image table',
    },
  }),
  mounted() {
    this.subjectInput = this.$refs.subjectInput as typeof HTMLInputElement;
  },
  methods: {
    updateTemplate() {
      this.$emit('updateTemplate', this.formData);
    },
    createTemplate() {
      this.$emit('createTemplate', this.formData);
    },
    handleElementFocus(field) {
      this.currentlyActiveField = field;
    },
    handlePlaceholderSelect(content) {
      if (!this.currentlyActiveField) return;

      const placeholder = `[${content}]`;

      if (this.currentlyActiveField == 'editorInput') {
        getTinymce().activeEditor.insertContent(placeholder);
      }

      if (this.currentlyActiveField == 'subjectInput') {
        this.formData.subject = this.formData?.subject + placeholder;
      }
    },
    limitInput(event) {
      this.formData.body = this.formData.body.substring(0, MAX_LENGTH);

      if (this.formData.body.length >= MAX_LENGTH) {
        event?.preventDefault();
      }
    },
  },
  watch: {
    template(values) {
      this.updateMode = false;

      if (typeof values === 'object' && typeof values.id !== 'undefined') {
        this.updateMode = true;
        this.formData = values;
      }
    },
  },
});
